import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { useQuery } from '~/lazy_apollo/client';
import { useLocation } from 'react-router-dom';
import { withIntl } from '../../../../utils/withIntl';
import { withRestaurant } from '../../../../utils/withRestaurant';
import { withSnackbar } from '../../../../utils/withSnackbar';
import { closeAddToCartModal, openMenuItemModal, setDiscrepancyMenuItemIds, setMenuItemId } from '../../../../shared/DishActions';
import createMenuItemCartSelectedMenuItemMutation from '../../../../libs/gql/mutations/menu_item_carts/createMenuItemCartSelectedMenuItemMutation.gql';
import menuItemModifierGroupsQuery from '../../../../libs/gql/queries/menus/menuItemModifierGroupsQuery.gql';

import BasicModal from '../../../../admin/shared/BasicModal';
import Loading from '../../../../shared/Loading';
import SelectedItemForm from '../SelectedItemForm/SelectedItemForm';
import LoadedModifierGroupsProvider from '../LoadedModifierGroupsProvider';
import { MenuItemQuery } from '../../MenuItemQuery';

const isNotAnOnlineOrderingPage = (urlHash) => {
  if (!urlHash || urlHash.length === 0 || !urlHash.includes('menu') || !urlHash.includes('location=')) {
    return true;
  }
  const page = urlHash.replace('#', '').split('?')[0];
  if (page !== 'menu') {
    return true;
  }
  const locationName = urlHash.split('location=').at(-1);
  return !locationName || locationName.length === 0;
};

const AddToCartModal = ({ t, theme }) => {
  const menuItemId = useSelector(state => state.dishes.menuItemId);
  const menuItemCartId = useSelector(state => state.menuItemCart.menuItemCartId);
  const menuItemCartSpecialInstructionsMaxLength = useSelector(state => state.menuItemCart.menuItemCartSpecialInstructionsMaxLength);
  const menuItemCartIsScheduled = useSelector(state => state.menuItemCart.menuItemCartIsScheduled);
  const menuItemCartScheduledAt = useSelector(state => state.menuItemCart.menuItemCartScheduledAt);
  const menuItemCartType = useSelector(state => state.menuItemCart.menuItemCartType);
  const showAddToCartModal = useSelector(state => state.dishes.showAddToCartModal);
  const showEditMenuItemCartModalFromMenu = useSelector(state => state.dishes.showEditMenuItemCartModalFromMenu);
  const discrepancyMenuItemIds = useSelector(state => state.dishes.discrepancyMenuItemIds);
  const dispatch = useDispatch();
  const { hash } = useLocation();

  const modernLayout = theme.dishLayout === 'modern_dish_layout';

  const { data: modGroupsData, loading: loadingModifierGroupsData } = useQuery(
    menuItemModifierGroupsQuery,
    {
      skip: !menuItemId,
      variables: {
        menuItemId,
      },
    },
  );

  const modifierGroupsData = modGroupsData?.menuItem?.modifierGroups;

  if (!menuItemId || !showAddToCartModal || showEditMenuItemCartModalFromMenu || loadingModifierGroupsData) {
    return <Loading />;
  }

  /**
   * If guest profile re-order all has applied discrepancy dish ids to the
   * dishes reducer, each dishId needs to be applied to the modal flow
   * until all options have been satisfied.
   */
  const onCloseModal = () => {
    if (discrepancyMenuItemIds.length > 0) {
      const updatedIds = discrepancyMenuItemIds.filter(id => id !== menuItemId);
      dispatch(setDiscrepancyMenuItemIds([...updatedIds]));
      dispatch(setMenuItemId(updatedIds[0]));
    } else {
      dispatch(closeAddToCartModal());
    }
  };

  if (modernLayout && isNotAnOnlineOrderingPage(hash)) {
    return null;
  }

  if (modernLayout) {
    dispatch(openMenuItemModal(menuItemId, true));
    return null;
  }
  return (
    <BasicModal
      closeModal={() => onCloseModal()}
      fullScreenMobile
      PaperProps={{
        'aria-label': t('consumer.ordering.add_to_order'),
      }}
      scroll="paper"
      size="md"
      show={showAddToCartModal}
    >
      <MenuItemQuery menuItemId={menuItemId}>
        {({ data, loading }) => {
          if (loading || !data || !data.menuItem) {
            return <Loading />;
          }
          const { menuItem } = data;
          return (
            <LoadedModifierGroupsProvider initialModifierGroups={modifierGroupsData}>
              <SelectedItemForm
                closeModal={onCloseModal}
                menuItem={menuItem}
                menuItemCartId={menuItemCartId}
                menuItemCartIsScheduled={menuItemCartIsScheduled}
                menuItemCartScheduledAt={menuItemCartScheduledAt}
                menuItemCartType={menuItemCartType}
                menuItemCartSpecialInstructionsMaxLength={menuItemCartSpecialInstructionsMaxLength}
                selectedMenuItemMutation={createMenuItemCartSelectedMenuItemMutation}
              />
            </LoadedModifierGroupsProvider>
          );
        }}
      </MenuItemQuery>
    </BasicModal>
  );
};

AddToCartModal.propTypes = {
  showSnackbar: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withRestaurant,
  mapProps(({ restaurant, ...props }) => ({
    ...props,
    restaurant,
    theme: restaurant.theme,
  })),
  withIntl,
  withSnackbar,
)(AddToCartModal);
