import React from 'react';

import Query from '../../shared/Query';
import menuItemQuery from '../../libs/gql/queries/menus/menuItemQuery.gql';
import { GetItemContext } from './GetItemContext';

export const MenuItemQuery = ({ menuItemId, children }) => {
  const getItem = React.useContext(GetItemContext);
  const cachedMenuItem = getItem(menuItemId);
  if (cachedMenuItem) {
    return children({ data: { menuItem: cachedMenuItem }, loading: false });
  }

  return <Query query={menuItemQuery} variables={{ id: menuItemId }}>{children}</Query>;
};
